import { Color } from '@/theme/partials/palette.css';
import { vars } from '@/theme/vars.css';
import { Skeleton, SkeletonProps } from '@mui/material';
import SimpleGrid, { SimpleGridProps } from '../SimpleGrid';
import Flex, { FlexProps } from '../ui/Flex';

export type SkeletonCardsProps = {
  amount: number;
  SkeletonItems: SkeletonCard;
} & Omit<SimpleGridProps, 'children'>;

const SkeletonCards = ({ amount, SkeletonItems, ...props }: SkeletonCardsProps) => {
  const items: { id: string | number }[] = Array.from({ length: amount }, (_, i) => ({ id: i }));

  return amount > 1 ? (
    <SimpleGrid rowGutter {...props}>
      {items.map((_, i) => {
        return <SkeletonCard key={i} {...SkeletonItems} />;
      })}
    </SimpleGrid>
  ) : (
    <SkeletonCard {...SkeletonItems} />
  );
};

type SkeletonCard = FlexProps<
  'div',
  {
    items: SkeletonProps[];
    bgcolor?: Color;
  }
>;

export const SkeletonCard = ({
  items = [],
  bgcolor = 'primaryTintLighter',
  ...props
}: SkeletonCard) => {
  return (
    <Flex cx={{ rowGap: '3xs' }} {...props}>
      {items.map((item, i) => {
        return (
          <Skeleton
            key={i}
            animation="wave"
            sx={{
              bgcolor: vars.palette.colors[bgcolor],
            }}
            {...item}
          />
        );
      })}
    </Flex>
  );
};

export default SkeletonCards;
