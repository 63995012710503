import { makeNonNullableArray } from '@liquorice/utils';
import { EventIndexEntriesQueryVariables } from '__generated__/graphql';
import { useMemo } from 'react';
import { DateRange } from '../parsers/common';
import { parseEventTimeFragment } from '../parsers/common/parseEventTime';
import { fmtDateVar } from '../utils/fmtDateVar';
import { toIsoString } from '../utils/format';
import { useEventTimeEntries } from './useEventTimeEntries';
import { useEvents } from './useEvents';

export const useEventIndexEntries = (
  variables: EventIndexEntriesQueryVariables,
  dateRange?: DateRange,
  useCurrentDate?: boolean
) => {
  let date: string[] | undefined = undefined;

  const { end, start } = dateRange ?? {};
  const currentDate = new Date();

  date = useCurrentDate ? fmtDateVar(toIsoString(currentDate)) : fmtDateVar(start, end);

  // Move useEventTimeEntries to the top level so it's always called
  const timeFields = useEventTimeEntries({ date });

  const id = useMemo(
    () =>
      timeFields?.result
        ? makeNonNullableArray(timeFields.result.map((entry) => entry?.ownerId))
        : undefined,
    [timeFields?.result]
  );

  // Get the event entries
  const eventVariables = useMemo(() => ({ ...variables, id }), [variables, id]);
  const { items, loading, error } = useEvents(eventVariables) ?? {};

  // No access to eventTime within GraphQL query/variables
  const sortedItems = useMemo(() => {
    return items
      ? [...items].sort((a, b) => {
          const dateA = parseEventTimeFragment(a.eventTime)[0]?.date || '';
          const dateB = parseEventTimeFragment(b.eventTime)[0]?.date || '';
          return new Date(dateA).getTime() - new Date(dateB).getTime();
        })
      : [];
  }, [items]);

  return {
    sortedItems,
    loading,
    error,
  };
};
