import { EVENT_TIME_QUERY } from '@/gql/queries/eventIndexQueries.gql';
import { useQuery } from '@apollo/client';
import { EventTimeEntriesQueryVariables } from '__generated__/graphql';
import { parseEventTimeFragment } from '../parsers/common/parseEventTime';

export const useEventTimeEntries = (variables: EventTimeEntriesQueryVariables) => {
  const { data, loading, error } = useQuery(EVENT_TIME_QUERY, {
    variables,
  });
  if (error) return null;

  const timeFieldEntries = data?.eventTimeFieldEntries;

  const result = parseEventTimeFragment(timeFieldEntries);

  return {
    result,
    loading,
    error,
  };
};
