import Image from '@/components/Image';
import Box from '@/components/ui/Box';
import Divider from '@/components/ui/Divider';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { createBlock } from '@/lib/parsers/blocks';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { toBoolean } from '@liquorice/utils';
import { gql } from '__generated__';
import React from 'react';
import { BlockContainer } from '../..';

export const LOGO_AND_TEXT_ITEM_BLOCK_FRAGMENT = gql(`
  fragment logoAndTextItemBlock on logoAndTextItem_Entry {
    title
    imageMultiple {
      ...image
    }
    lightswitch
  }  
`);

export const LOGO_AND_TEXT_BLOCK_FRAGMENT = gql(`
  fragment logoAndTextBlock on blocks_logoAndText_BlockType {
    logoAndTextItem {
      ...logoAndTextItemBlock
    }
  }
`);

export const LogoAndTextBlock = createBlock(LOGO_AND_TEXT_BLOCK_FRAGMENT, ({ data, meta }) => {
  const { logoAndTextItem } = data;

  const parseLogos = createFragmentArrayParser(LOGO_AND_TEXT_ITEM_BLOCK_FRAGMENT, (data) => data);
  const logos = parseLogos(logoAndTextItem);

  return (
    <BlockContainer
      {...{
        colorSet: 'white',
        paper: true,
        meta,
        marginY: true,
        disableTransition: true,
      }}>
      {logos.map((logo, i) => {
        return (
          <React.Fragment key={i}>
            <Txt as="span" variant="h5">
              {logo.title}
            </Txt>
            <Divider direction="horizontal" cx={{ mY: 'md', color: 'primaryTintLight' }} />
            <Grid>
              {logo.imageMultiple?.map((image, i: number) => {
                return (
                  <Grid.Col key={i} sm={6} md={3}>
                    <Box cx={{ pX: '2xs' }}>
                      <Image
                        style={{ height: '6.875rem', padding: '12px' }}
                        alt=""
                        captions={toBoolean(logo.lightswitch)}
                        fixedRatio
                        contain
                        data={image}
                      />
                    </Box>
                  </Grid.Col>
                );
              })}
            </Grid>
          </React.Fragment>
        );
      })}
    </BlockContainer>
  );
});

export default LogoAndTextBlock;
