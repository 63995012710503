import BlockHeader from '@/components/BlockHeader';
import Icon from '@/components/Icon';
import SearchForm from '@/components/SearchForm';
import Btn from '@/components/ui/Btn';
import Flex from '@/components/ui/Flex';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { parseLinkFieldMultiple } from '@/lib/parsers/common/parseLinkFieldMultiple';
import { useAppContext } from '@/lib/store/hooks';
import { vars } from '@/theme/vars.css';
import { parseUri, toStringOrNull } from '@liquorice/utils';
import { gql } from '__generated__';
import { BlockContainer } from '../..';

export const EVENT_SEARCH_BLOCK_FRAGMENT = gql(`
  fragment eventSearch on blocks_eventSearch_BlockType {
    heading
    linkFieldMultiple {
      ...linkFieldMultiple
    }
  }
`);

export const EventSearchBlock = createBlock(EVENT_SEARCH_BLOCK_FRAGMENT, ({ data, meta }) => {
  const { heading } = data;
  const { commonLinks } = useAppContext() ?? {};
  const eventIndexTitle = toStringOrNull(commonLinks?.eventIndex.title);
  const eventIndexUri = parseUri(commonLinks?.eventIndex.uri);
  const linkFieldMultiple = parseLinkFieldMultiple(data.linkFieldMultiple);

  return (
    <BlockContainer
      {...{
        colorSet: 'primaryTintLighter',
        paper: true,
        meta,
        marginY: 'none',
        paddingY: true,
        disableTransition: true,
        overflow: true,
        customAnchor: heading,
      }}>
      <Grid>
        <Grid.Col offsetMd={3} md={6}>
          <BlockHeader
            maxWidth="none"
            heading={heading}
            alignItems="center"
            HeadingProps={{ variant: 'h3', cx: { mB: 'md' } }}
          />
          <SearchForm
            hideClose
            hideSearch
            redirect="/whats-on/all-events"
            InputWrapProps={{
              direction: 'row',
            }}
            TextFieldProps={{
              sx: {
                borderRadius: 1,
                bgcolor: vars.palette.colorSets.white.main,
              },
              variant: 'outlined',
              InputProps: {
                endAdornment: <Icon name="search" cx={{ color: 'primary', fontSize: 'h4' }} />,
              },
            }}
            cx={{ mB: 'md' }}
          />
          <Flex direction="row" justifyContent="between" wrap="wrap">
            {eventIndexUri && (
              <Btn
                variant="text"
                color="primary"
                underline="always"
                href={eventIndexUri}
                startIcon="indexIcon">
                {eventIndexTitle}
              </Btn>
            )}
            <Flex direction="row" columnGap="sm">
              {linkFieldMultiple?.map((link, i) => (
                <Btn
                  key={i}
                  variant="text"
                  cx={{ fontSize: 'small' }}
                  color="primaryTintDark"
                  underline="always"
                  {...link}
                />
              ))}
            </Flex>
          </Flex>
        </Grid.Col>
      </Grid>
    </BlockContainer>
  );
});

export default EventSearchBlock;
