import { EVENT_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { EVENT_INDEX_QUERY } from '@/gql/queries/eventIndexQueries.gql';
import { useQuery } from '@apollo/client';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { EventIndexEntriesQueryVariables } from '__generated__/graphql';

const entryParser = createFragmentArrayParser(EVENT_CARD_FRAGMENT, (data) => data);

export const useEvents = (variables: EventIndexEntriesQueryVariables) => {
  const { data, loading, error } = useQuery(EVENT_INDEX_QUERY, {
    variables,
    skip: !variables.id, // Prevents running the query if id is undefined
  });

  if (error) return null;

  const items = entryParser(data?.eventEntries);

  return {
    items,
    loading,
    error,
  };
};
