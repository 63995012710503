'use client';

import Box from '@/components/ui/Box';
import Btn from '@/components/ui/Btn';
import Divider from '@/components/ui/Divider';
import Flex from '@/components/ui/Flex';
import Txt from '@/components/ui/Txt';
import { GENRE_CATEGORY_FRAGMENT } from '@/gql/fragments/categories.gql';
import { useAppContext } from '@/lib/store/hooks';
import { withFragmentArray } from '@liquorice/gql-utils';
import { parseUri } from '@liquorice/utils';

const EventLayoutDivider = withFragmentArray(GENRE_CATEGORY_FRAGMENT, (data) => {
  const { commonLinks } = useAppContext() ?? {};
  const eventIndexUri = parseUri(commonLinks?.eventIndex.uri);

  return (
    <Box cx={{ mY: '3xl' }}>
      <Divider cx={{ color: 'primaryTintLight', mB: 'md' }} />
      <Flex direction="row" columnGap="xs">
        {data?.map((category) => {
          return (
            <Btn
              key={category.id}
              size="tiny"
              variant="filled"
              color="primaryTintLighter"
              rounded="large"
              href={`${eventIndexUri}?${category.groupHandle}=${category.id}`}>
              <Txt variant="tiny" monoFont uppercase weight="regular">
                {category.title}
              </Txt>
            </Btn>
          );
        })}
      </Flex>
    </Box>
  );
});

export default EventLayoutDivider;
