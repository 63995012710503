import BlockHeader from '@/components/BlockHeader';
import * as richTextStyles from '@/components/Blocks/BlockComponents/RichTextBlock/RichTextBlock.css';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { Formie } from '@liquorice/formie-react';
import { firstNonNullable } from '@liquorice/utils';
import { gql } from '__generated__';
import { BlockContainer } from '../../BlockContainer';

export const FORM_BLOCK_FRAGMENT = gql(`
  fragment formBlock on blocks_form_BlockType {
    heading
    content: htmlContentSimple
    form {
      id
    }
  }
`);

export const FormBlock = createBlock(FORM_BLOCK_FRAGMENT, ({ data, meta }) => {
  const { heading, content } = data;

  const form = firstNonNullable(data.form);

  return (
    <BlockContainer
      {...{
        meta,
        disableTransition: true,
        marginY: true,
        customAnchor: heading,
      }}>
      <Grid rowGutter={false} cx={{ rowGap: 'xl' }}>
        <Grid.Col>
          <BlockHeader
            maxWidth="none"
            heading={heading}
            HeadingProps={{ variant: 'h3', color: 'primary', cx: { mB: 'md' } }}
            content={content}
            ContentProps={{ className: richTextStyles.withRichText }}
          />
        </Grid.Col>
        <Grid.Col>{!!form && <Formie queryVariables={form.id} />}</Grid.Col>
      </Grid>
    </BlockContainer>
  );
});

export default FormBlock;
