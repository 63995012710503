'use client';

import { ARTICLE_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { ArticleCard as Card } from '../Cards/ArticleCard';
import createCard from './createCard';

const ArticleCard = createCard(ARTICLE_CARD_FRAGMENT, ({ data, overrides, ...props }) => {
  if (!data) return null;

  return <Card {...{ data, overrides }} />;
});

export default ArticleCard;
